export const PICKUP_FILTER = "pickup";
export const DELIVERY_FILTER = "delivery";
export const OPEN_NOW_FILTER = "open_now";
export const PRE_ORDER_FILTER = "pre_order";
export const RECREATIONAL_FILTER = "recreational";
export const MEDICAL_FILTER = "medical";
export const HAS_DEALS = "deals";

export const listingSortDisplayStrings: Record<string, string> = {
  "a-z": "A - Z",
  default: "Recommended",
  distance: "Distance",
  "highest-rated": "Highest rated",
  "most-reviews": "Most reviews",
  "online-ordering": "Online Ordering",
  "z-a": "Z - A",
};
