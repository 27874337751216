export const ZIP_FIELD_ERROR_MESSAGE =
  "Please enter a valid zip code in the U.S. or Canada.";

export const maybeZipCodeUS = (zip: string) =>
  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);

export const maybeZipCodeCA = (zip: string) =>
  /(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$)/.test(zip);

export const validateZip = (zip: string) =>
  maybeZipCodeUS(zip) || maybeZipCodeCA(zip);

export const validateZipField = (value: string) =>
  /(^\d{0,5}$)|(^\d{5}-\d{0,4}$|(^[A-Za-z]$)|(^[A-Za-z]\d$)|(^[A-Za-z]\d[A-Za-z]$)|(^[A-Za-z]\d[A-Za-z][ -]$)|(^[A-Za-z]\d[A-Za-z][ -]?\d$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]$)|(^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$))/.test(
    value,
  );

export const formatZipParameter = (zip: string) => {
  if (/^[A-Za-z]/.test(zip)) {
    if (zip.length === 6) {
      return `${zip.slice(0, 3)} ${zip.slice(3, 6)}`;
    }
    return zip.replace("-", " ");
  }
  return zip.slice(0, 5);
};
